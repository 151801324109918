.iframe-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .6);

  .close {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translate(141px, -217px);
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
}

#iframeId {
  width: 100%;
  height: 100%;
}